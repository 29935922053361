import { addressAtom } from "@/store"
import { Button, Form, Input, InputNumber, message, Slider } from "antd"
import { useAtomValue } from "jotai"
import { useEffect, useState } from "react"
import { CreateTokenParamsType, createTokenAction } from "@/request/api"
import { useRequest } from "ahooks"
import { broadcastTxByUnisate, getAddressPubKey, getBitcoinFees, getUtxoInfo, getUtxos, hexToPSBT, hexToPSBTForUtxos, signMessageByUnisat } from "@/utils"
import BigNumber from "bignumber.js"
import { TokenItemType } from "../SendDialog"
import { AssetInfo } from "@/request/asset"

export const TransferTokenDialog = ({ onClose, token, assetInfo, tokenAssets }: { onClose: () => void, token: TokenItemType, assetInfo: AssetInfo, tokenAssets: string[] }) => {
  const [form] = Form.useForm()
  const userAddress = useAtomValue(addressAtom)
  const [transFee, setTransFee] = useState<number | null>()
  const [satoshisFee, setSatoshisFee] = useState<number>(0)

  const { data: feeOptions } = useRequest(async () => {
    const res = await getBitcoinFees()
    if (!res) return {}
    const keys = Object.keys(res).map((item) => Number(item)).filter((key, index) => index < 10)

    const _feeOptions = keys.reduce((prev, item) => {
      prev[item] = {
        fee: new BigNumber(res[item]).multipliedBy(4000).toFixed(2),
        baseFee: res[item].toFixed(2)
      }

      return prev
    }, {} as any)

    return _feeOptions
  }, {
    ready: true,
    pollingInterval: 10000
  })

  const { run, loading: issueLoading } = useRequest(async (params: CreateTokenParamsType) => await createTokenAction(params), {
    manual: true,
    debounceWait: 1000,
    onSuccess: async (res: any) => {
      setTransFee(res?.btc_fee)

      const quantity = form.getFieldValue('quantity')

      try {
        const utxos = await getUtxos(userAddress)
        const psbtHex = hexToPSBTForUtxos(res?.tx_hex, utxos) as string
        const signedPsbt = await signMessageByUnisat(psbtHex) as string
        await broadcastTxByUnisate(signedPsbt)

        message.success(`You have issued ${new BigNumber(quantity).toString()} additional quantity on your token ${assetInfo.asset}. This action will take some time to complete, and will appear as a Pending Action until confirmed on the network,Until that time, the wallet will not reflect the change, Please be patient.`)
        onClose?.()
      } catch (e: any) {
        message.error(e.message || e)
      }
    },
    onError: (e: any) => {
      message.error(e.message || e)
    }
  })

  const handleRequest = async (transfer_destination: string, fee: number) => {
    const pubkey = await getAddressPubKey(userAddress)

    const params: any = {
      asset: assetInfo.asset,
      source: token.address,
      divisible: assetInfo.divisible,
      fee_per_kb: fee,
      extended_tx_info: true,
      transfer_destination: transfer_destination,
      disable_utxo_locks: true,
      description: '',
      pubkey,
      quantity: 0,
    }

    run(params)
  }

  return <div className="bg-[#111318]">
    <p>Enter the Bitcoin address you would like to permanently transfer ownership of this token to. (Be aware that this will not transfer the actual units of this token that you own to this address -- you can do that with a separate Send transaction.)</p>
    <p className="mt-[10px]">Please NOTE that this action is irrversible!</p>
    <Form
      form={form}
      className="mt-[10px]"
      layout="vertical"
      initialValues={{
        feeRate: 10,
      }}
      onValuesChange={(_, allVal) => {
        if (allVal.feeRate) {
          setSatoshisFee(feeOptions[11 - allVal?.feeRate].baseFee)
        }

      }}
      onFinish={(value) => {
        const { transfer_destination, feeRate } = value
        const fee = Number(feeOptions[feeRate].fee)

        handleRequest(transfer_destination, fee)
      }}>
      <Form.Item label={'Transfer To'} name={'transfer_destination'} rules={[{ required: true, }]}>
        <Input className="w-full" />
      </Form.Item>
      <Form.Item layout="horizontal" label={'BitCoin Fee'} name={'feeRate'}>
        <Slider max={10} min={1} step={1} className="w-[250px]" />
      </Form.Item>
      <Form.Item dependencies={['feeRate']}>
        {
          ({ getFieldValue }) => {
            const feeRate = getFieldValue('feeRate')

            return (
              <div>
                {
                  transFee && <p className={'text-[#4A90E2] text-[14px]'}>
                    Transaction fee: {transFee / 100000000} BTC
                  </p>
                }
                <p className={'text-[#fff] text-[14px]'}>
                  Estimated wait: between {feeRate === 10 ? `1` : `1 ~ ${11 - feeRate}`} blocks ({satoshisFee}  Satoshis/Byte)
                </p>
              </div>
            )
          }
        }

      </Form.Item>
      <Form.Item>
        <div className={'flex justify-end'}>
          <Button color="default" onClick={() => onClose()}>Cancel</Button>
          <Button
            className="ml-[10px]"
            loading={issueLoading}
            type="primary"
            onClick={() => form.submit()}
          >
            Issue Additional
          </Button>
        </div>
      </Form.Item>
    </Form>
  </div>
}