import { Table } from '@/components/ui/Table'
import styles from './index.module.scss'
import { TableFooter } from '@/components/TableFooter'
import { FormatedOpenOrder, useOpenOrdersFormatedData } from './_hooks/useOpenOrders'
import { NiceModal } from '@/components/dialog'
import { SecondaryConfirmationDialog } from '@/components/dialog/secondary-confirmation'
import { CreateCancelOrderParams, useCreateCancelOrder } from './_hooks/useCancelOrder'
import { cn } from '@/utils/cn'
import { message } from 'antd'
import { broadcastTxByUnisate, encryptionAddress, getBitcoinFees, getUtxos, hexToPSBTForUtxos, signMessageByUnisat } from '@/utils'
import { useAtomValue } from 'jotai'
import { addressAtom } from '@/store'
import { useRequest } from 'ahooks'
import BigNumber from 'bignumber.js'

export const OpenOrders = () => {
  const { formatedOpenOrders } = useOpenOrdersFormatedData()

  const { createCancelMutateAsync } = useCreateCancelOrder()

  const userAddress = useAtomValue(addressAtom)

  const { data: feeOptions } = useRequest(async () => {
    const res = await getBitcoinFees()
    if (!res) return {}
    const keys = Object.keys(res).map((item) => Number(item)).filter((key, index) => index < 10)

    const _feeOptions = keys.reduce((prev, item) => {
      prev[item] = {
        fee: new BigNumber(res[item]).multipliedBy(4000).toFixed(2),
        baseFee: res[item].toFixed(2)
      }

      return prev
    }, {} as any)

    return _feeOptions
  }, {
    ready: true,
    pollingInterval: 10000
  })

  function handleCancelOrder(order: FormatedOpenOrder) {
    NiceModal.show(SecondaryConfirmationDialog, {
      title: 'Confirm cancellation order',
      message: 'Requests to cancel an order will still consume BTC (necessary to pay the Bitcoin miner fee). To avoid this, let your order expire naturally.',
      confirm: {
        cb: async () => {
          try {
            const params: CreateCancelOrderParams = {
              offer_hash: order.tx_hash,
              source: order.source,
              fee: Number(feeOptions?.[1]?.fee) ?? 0
            }

            const hash = await createCancelMutateAsync(params)
            const utxos = await getUtxos(userAddress)
            const psbtHex = hexToPSBTForUtxos(hash, utxos) as string
            const signedPsbt = await signMessageByUnisat(psbtHex) as string

            await broadcastTxByUnisate(signedPsbt)

            message.success('Transaction Submitted! Please kindly wait for the block confirmation!')
          } catch (error: any) {
            message.error(error)
          }
        },
      },
    })
  }

  return (
    <div className={cn(styles.wrap, 'py-9 px-2.5 sm:px-9')}>
      <div className={styles.titles}>
        <p>Open Orders</p>
      </div>
      <div className={cn(styles.body, '!px-2.5 sm:!px-5')}>
        <div className={cn(styles.tableWrap, 'overflow-x-auto')}>
          <Table
            columns={[
              { dataIndex: 'address_label', label: 'Address', render: (record: any) => <span className='px-[10px]'>{encryptionAddress(record.address_label)}</span> },
              { dataIndex: 'give_quantity_str', label: 'Sell quantity' },
              { dataIndex: 'get_quantity_str', label: 'Buy quantity' },
              { dataIndex: 'give_remaining_str', label: 'Sell Remaining' },
              { dataIndex: 'get_remaining_str', label: 'Buy Remaining' },
              {
                dataIndex: 'block',
                label: 'Block',
                render: (order: FormatedOpenOrder) => {
                  return <> #{order.expire_index}</>
                },
              },
              {
                dataIndex: 'action',
                align: 'right',
                render: (order: FormatedOpenOrder) => {
                  return (
                    <button className="cursor-pointer" disabled={false} onClick={() => handleCancelOrder(order)}>
                      Cancel
                    </button>
                  )
                },
                label: 'Action',
              },
            ]}
            data={formatedOpenOrders}
            noData={<TableFooter />}
          />
        </div>
      </div>
    </div>
  )
}
