import { useNavigate } from 'react-router-dom'
import styles from './index.module.scss'


type MenuItemProps = {
    title: string,
    url: string
    jumpOut?: boolean,
    disable?: boolean
}

const menus: MenuItemProps[] = [
    {
        title: 'Bridge',
        url: '',
        jumpOut: true,
        disable: true
    },
    {
        title: 'Gitbook',
        url: 'https://bitroot.gitbook.io/bitroot',
        jumpOut: true
    },
    {
        title: 'Github',
        url: 'https://github.com/BitrootD',
        jumpOut: true
    }, {
        title: 'Faucet',
        url: 'http://13.229.107.0/api/v2/faucet/claim?address=msN6AvRmKE2EuUctnVobzNrGPjNfedzAHn',
        jumpOut: true,
        disable: false
    }
]



export const Menus = () => {
    const navigate = useNavigate()


    return (
        <div className={styles.menus}>
            {
                menus.map((menuItem: MenuItemProps) => (<div
                    key={menuItem.title}
                    className={styles.menuItem}
                    onClick={() => {
                        if(menuItem.disable) return false

                        if (menuItem.jumpOut) {
                            window.open(menuItem.url, '__blank')
                            return
                        }

                        navigate(menuItem.url)
                    }}
                >
                    {menuItem.title}
                </div>))
            }
        </div>
    )
}
