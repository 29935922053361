import { Header } from './Header'
import styles from './index.module.scss'
import { NavLink, Outlet, useLocation, useNavigate } from 'react-router-dom'
import { Side, SideItems } from './Side'
import { useAtom, useAtomValue } from 'jotai'
import { addressAtom, jotaiStore, showMobileMenuAtom } from '@/store'
import { useEffect } from 'react'
import { restoreConnection } from '@/utils'
import { LOCAL_BTC_ADDRESS_KEY } from '@/contains'
import Menu from 'assets/images/header/Menu.svg'
import { useFeeRate } from '@/hooks/useFees'
import { message } from 'antd'

export const Layout = () => {
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const address = useAtomValue(addressAtom)
  const [showMobileMenu, setShowMobileMenu] = useAtom(showMobileMenuAtom)
  useFeeRate()

  const [_, contextHolder] = message.useMessage();

  useEffect(() => {
    if (address) {
      navigate('/balance')
    } else {
      navigate('/')
    }
  }, [address])

  useEffect(() => {
    if (window.unisat) {
      window.unisat.on('accountsChanged', (accounts: any[]) => {
        if (accounts.length > 0) {
          localStorage.setItem(LOCAL_BTC_ADDRESS_KEY, accounts[0]);
        jotaiStore.set(addressAtom, accounts[0])
        } else {
          localStorage.removeItem(LOCAL_BTC_ADDRESS_KEY);
        }
      });

      window.unisat.on('disconnect', () => {
        jotaiStore.set(addressAtom, '')
        localStorage.removeItem(LOCAL_BTC_ADDRESS_KEY);
      });
    }

    setTimeout(() => restoreConnection(), 0)
  }, [])

  return (
    <div className={styles.layout}>
      {contextHolder}
      {
        showMobileMenu && <div className={styles.menuPage}>
          <div className={styles.menuPage_header}>
            <img src={Menu} alt="" onClick={() => setShowMobileMenu(false)} />
          </div>
          <div className={styles.menuPage_container}>
            {
              SideItems?.map((sideItem) => {
                return (
                  <div className={[styles.menuPage_container_item, pathname.startsWith(sideItem.path) ? styles.currentItem : ''].join(' ')} key={sideItem.path} onClick={() => {
                    setShowMobileMenu(false)
                    navigate(sideItem.path)
                  }}>
                    <span>{sideItem.title}</span>
                  </div>

                )
              })
            }
          </div>
        </div>
      }
      <Header />
      <div className={styles.container}>
        {pathname !== '/' && <Side />}
        <div className={styles.container_body}>
          <Outlet />
        </div>
      </div>
    </div>
  )
}
