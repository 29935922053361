import { addressAtom } from "@/store"
import { Button, Form, message, Slider } from "antd"
import { useAtomValue } from "jotai"
import { useState } from "react"
import { CreateTokenParamsType, createTokenAction } from "@/request/api"
import { useRequest } from "ahooks"
import { broadcastTxByUnisate, getAddressPubKey, getBitcoinFees, getUtxos, hexToPSBTForUtxos, signMessageByUnisat } from "@/utils"
import BigNumber from "bignumber.js"
import { TokenItemType } from "../SendDialog"
import { AssetInfo } from "@/request/asset"

export const LockTokenDialog = ({ onClose, token, assetInfo }: { onClose: () => void, token: TokenItemType, assetInfo: AssetInfo }) => {
  const [form] = Form.useForm()
  const userAddress = useAtomValue(addressAtom)
  const [transFee, setTransFee] = useState<number | null>()
  const [satoshisFee, setSatoshisFee] = useState<number>(0)
  const [fee, setFee] = useState<number>(0)
  const { data: feeOptions } = useRequest(async () => {
    const res = await getBitcoinFees()
    if (!res) return {}
    const keys = Object.keys(res).map((item) => Number(item)).filter((key, index) => index < 10)

    const _feeOptions = keys.reduce((prev, item) => {
      prev[item] = {
        fee: new BigNumber(res[item]).multipliedBy(4000).toFixed(2),
        baseFee: res[item].toFixed(2)
      }

      return prev
    }, {} as any)

    return _feeOptions
  }, {
    ready: true,
    pollingInterval: 10000
  })

  const { run, loading: lockLoading } = useRequest(async (params: CreateTokenParamsType) => await createTokenAction(params), {
    manual: true,
    debounceWait: 1000,
    onSuccess: async (res: any) => {
      setTransFee(res?.btc_fee)

      try {
        const utxos = await getUtxos(userAddress)
        const psbtHex = hexToPSBTForUtxos(res?.tx_hex, utxos) as string
        const signedPsbt = await signMessageByUnisat(psbtHex) as string
        await broadcastTxByUnisate(signedPsbt)

        message.success(`Your token has been locked. No more units of the token can be issued. This action will take some time to complete, and will appear as a Pending Actionuntil confirmed on the network. Until that time, the wallet will not reflect the change. Please be patient.`)
        onClose?.()
      } catch (e: any) {
        message.error(e.message || e)
      }
    },
    onError: (e: any) => {
      message.error(e.message || e)
    }
  })

  const handleRequest = async (fee: number) => {
    const pubkey = await getAddressPubKey(userAddress)
    const params = {
      asset: assetInfo.asset,
      source: token.address,
      divisible: assetInfo.divisible,
      pubkey: pubkey as string,
      fee_per_kb: fee as any,
      description: 'LOCK',
      quantity: 0,
    }

    run(params)
  }

  return <div className="bg-[#111318]">
    <p>By locking your token, you will not be able to issue more units of in the futre.</p>
    <p className="text-[red]">Please NOTE that this action is irreversible!</p>
    <Form
      form={form}
      className="mt-[10px]"
      layout="vertical"
      initialValues={{
        feeRate: 10,
      }}
      onValuesChange={(_, allVal) => {
        if (allVal.feeRate) {
          setSatoshisFee(feeOptions[11 - allVal?.feeRate].baseFee)
        }
      }}
      onFinish={(value) => {
        const { feeRate } = value
        const fee = Number(feeOptions[feeRate].fee)
        setFee(fee)

        handleRequest(fee)
      }}>

      <Form.Item layout="horizontal" label={'BitCoin Fee'} name={'feeRate'}>
        <Slider max={10} min={1} step={1} className="w-[250px]" />
      </Form.Item>
      <Form.Item dependencies={['feeRate']}>
        {
          ({ getFieldValue }) => {
            const feeRate = getFieldValue('feeRate')

            return (
              <div>
                {
                  transFee && <p className={'text-[#4A90E2] text-[14px]'}>
                    Transaction fee: {transFee / 100000000} BTC
                  </p>
                }
                {!!fee && <p className={'text-[#fff] text-[14px]'}>
                  Estimated wait: between {feeRate === 10 ? `1` : `1 ~ ${11 - feeRate}`} blocks ({satoshisFee}  Satoshis/Byte)
                </p>}
              </div>
            )
          }
        }

      </Form.Item>
      <Form.Item>
        <div className={'flex justify-end'}>
          <Button color="default" onClick={() => onClose()}>Cancel</Button>
          <Button
            className="ml-[10px]"
            loading={lockLoading}
            type="primary"
            onClick={() => form.submit()}
          >
            Issue Additional
          </Button>
        </div>
      </Form.Item>
    </Form>
  </div>
}