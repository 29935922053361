import styles from './index.module.scss'
import { isReady, getPreferencesInfo, getChainAddressInfo, storePreferences, getNormalizedBalances } from '@/request/api'
import { useMemo, useState } from 'react'
import { encryptionAddress, toBase64 } from '@/utils'
import { Button } from '@radix-ui/themes'
import menuIcon from 'assets/images/balance/menu.png'
import { SignMessageDialog } from '@/components/SignMessageDialog'
import { SignTransactionDialog } from '@/components/SignTransationDialog'
import { useRequest } from 'ahooks'
import { useAtom, useAtomValue, useSetAtom } from 'jotai'
import { ChainAddressInfoType, NormalizeBanlanceType, addressAtom, chainAddressInfoAtom, normalizeBanlanceAtom, preferencesAtom, quoteAssetsAtom } from '@/store'
import BigNumber from 'bignumber.js'
import { useQuery } from '@tanstack/react-query'
import { fetchAssetsInfoApi } from '@/request/asset'
import { DropdownMenu } from '@/components/ui/DropMenu'
import { TokenItem, TokenItemType, TokenType } from './_comp/token-item'
import { CreateTokenDialog } from '@/components/CreateTokenDialog'
import { Dialog } from '@/components/ui/NewDialog'

export const Balance = () => {
  const [preferences, setPreferences] = useAtom(preferencesAtom)
  const setQuoteAssets = useSetAtom(quoteAssetsAtom)
  const [chainAddress, setChainAddressInfo] = useAtom(chainAddressInfoAtom)
  const [normalizeBanlance, setNormalizeBalance] = useAtom(normalizeBanlanceAtom)

  const [showSignMessage, setShowSignMessage] = useState<boolean>(false)
  const [showSignTransaction, setShowSignTransaction] = useState<boolean>(false)
  const [showCreateToken, setShowCreateToken] = useState<boolean>(false)

  const userAddress = useAtomValue(addressAtom)

  useRequest(async () => await isReady(), {
    ready: !!userAddress,
    refreshDeps: [userAddress],
    onSuccess: (res: any) => {
      setQuoteAssets(() => res.quote_assets ?? [])
      runGetPreferencesInfo()
      return res
    }
  })

  const { run: runGetPreferencesInfo } = useRequest(async () => await getPreferencesInfo(toBase64(`${userAddress}_testnet`)), {
    manual: true,
    onSuccess: (res) => {
      const _preferences = res?.preferences ?? {}
      const _address_aliases = _preferences.address_aliases ?? ''

      if (_address_aliases) {
        setPreferences(() => res?.preferences)

        runGetChainAddressInfo(userAddress)
        runGetNormalizedBalances(userAddress)
      } else {
        const mewPreferences = {
          ...preferences,
          address_aliases: userAddress
        }

        runStorePreferences({
          ...mewPreferences,
        })

        runGetChainAddressInfo(userAddress)
        runGetNormalizedBalances(userAddress)
      }
    }
  })

  const { run: runStorePreferences } = useRequest(async (tmpPerferences) => await storePreferences(toBase64(`${userAddress}_testnet`), tmpPerferences), {
    manual: true,
    onSuccess: (res: any) => runGetPreferencesInfo()
  })

  const { run: runGetChainAddressInfo } = useRequest(async (address) => await getChainAddressInfo([address]), {
    manual: true,
    onSuccess: (res: any) => setChainAddressInfo(() => res),
    pollingInterval: 3000
  })


  const { run: runGetNormalizedBalances } = useRequest(async (address) => await getNormalizedBalances([address]), {
    manual: true,
    onSuccess: (res: any) => setNormalizeBalance(() => res ?? []),
    pollingInterval: 3000
  })

  const tokens: TokenItemType[] = useMemo(() => {
    const _tokens: TokenItemType[] = []
    const obj = chainAddress.find((chainItem) => chainItem.addr === userAddress) ?? {} as ChainAddressInfoType

    const balance = `${obj?.info?.balance ?? 0}` ?? '0'

    const btcBalance = new BigNumber(balance)

    const btcUnconfirm = new BigNumber(obj?.info?.unconfirmedBalance ?? '0')

    const btcBalanceObj: TokenItemType = {
      tokenType: TokenType.BTC,
      balance: btcBalance.toFixed(6),
      formattedBalance: btcBalance.plus(btcUnconfirm).toFixed(6),
      unconfirmedBalance: obj?.info?.unconfirmedBalance ?? '0',
      asset: '',
      address: ''
    }

    _tokens.push(btcBalanceObj)

    const others = normalizeBanlance.filter((other) => other.address === userAddress) ?? [] as NormalizeBanlanceType[]

    if (others.length) {
      others.forEach((normalToken) => {
        const token: TokenItemType = {
          tokenType: normalToken.asset_longname ? normalToken.asset_longname : normalToken.asset,
          balance: normalToken.normalized_quantity.toFixed(6),
          formattedBalance: new BigNumber(normalToken.normalized_quantity).toFixed(6),
          asset: normalToken.asset,
          address: normalToken.address
        }

        _tokens.push(token)

      })
    } else {
      const brtToken: TokenItemType = {
        tokenType: TokenType.BRT,
        balance: '0',
        formattedBalance: '0',
        asset: '',
        address: ''
      }
      _tokens.push(brtToken)
    }

    return _tokens
  }, [chainAddress, normalizeBanlance, userAddress])

  const items = [
    { label: 'Sign Message', onClick: () => setShowSignMessage(true) },
    { label: 'Sign Transaction', onClick: () => setShowSignTransaction(true) },
    { label: 'Create a Token', onClick: () => setShowCreateToken(true) },
  ]

  const tokenAssets = tokens.filter((item) => !!item.asset && item.tokenType !== TokenType.BRT && item.tokenType !== TokenType.BTC).map((token) => token.asset!)

  const { data: assetInfos } = useQuery({
    queryKey: [{ key: 'fetchAssetsInfoApi', asset: tokenAssets }],
    enabled: !!tokenAssets.length,
    queryFn: async () => {
      const rs = await fetchAssetsInfoApi(tokenAssets)
      return rs
    },
  })

  return (
    <div className={styles.balance}>
      <div className={'flex items-end justify-between'}>
        <div>
          <p className={styles.subject}>My Account Balances</p>
          <p className={styles.desc}> Using the Bitroot testnet requires consuming testnet BTC and testnet BRT. You can collect it through the faucet website.</p>
        </div>
        <DropdownMenu
          tirggerEl={<Button className={styles.pcAction} style={{ border: 'none' }}>Actions  <img className='w-[12px]' src={menuIcon} alt='' /></Button>}
          items={items}
        />
      </div>
      <div className={styles.tokenContainer}>
        <div className={styles.tokenContainer_header}>
          <span className={styles.tokenContainer_header_address}>{encryptionAddress(userAddress)}</span>
          <DropdownMenu
            tirggerEl={<Button color='gray' style={{ border: 'none' }}>Actions  <img className='w-[12px]' src={menuIcon} alt='' /></Button>}
            items={items}
          />
        </div>
        {
          tokens.map((token: TokenItemType) => {
            const isAssetsToken = !!token.asset && token.tokenType !== TokenType.BRT && token.tokenType !== TokenType.BTC
            if (isAssetsToken) {
              const assetInfo = assetInfos?.find((item) => item.asset === token?.asset)
              if (!assetInfo) return null
              return <TokenItem token={token} assetInfo={assetInfo} tokenAssets={tokenAssets} />
            }

            return <TokenItem token={token} tokenAssets={tokenAssets} />
          })
        }
      </div>
      {showSignMessage && <SignMessageDialog open={showSignMessage} onClose={() => setShowSignMessage(false)} />}
      {showSignTransaction && <SignTransactionDialog open={showSignTransaction} onClose={() => setShowSignTransaction(false)} />}
      {showCreateToken && <Dialog
        open={showCreateToken}
        title={'Create a token'}
        onClose={() => setShowCreateToken(false)}
        content={<CreateTokenDialog currentAddressNormalBanlance={normalizeBanlance} onClose={() => setShowCreateToken(false)} />}
      />}
    </div>
  )
}
