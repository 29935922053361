import { Table } from '@/components/ui/Table'
import styles from './index.module.scss'
import {  useState } from 'react'
import { TableFooter } from '@/components/TableFooter'
import {  addressAtom, AddressType } from '@/store'
import { useRequest } from 'ahooks'
import { getHistoryAction } from '@/request/api'
import dayjs from 'dayjs'
import { useAtomValue } from 'jotai'
import { encryptionAddress } from '@/utils'
import { cn } from '@/utils/cn'

export const History = () => {
  const userAddress = useAtomValue(addressAtom)

  const [list, setList] = useState<any[]>([])
  //  @ts-ignore
  useRequest(async () => await getHistoryAction(address), {
    ready: true,
    refreshDeps: [userAddress],
    onSuccess: (res: any[]) => {
      setList(() => res)
    },
  })

  return (
    <div className={cn(styles.wrap, 'py-9 px-2.5 sm:px-9')}>
      <div className={styles.titles}>
        <p>Account History</p>
      </div>
      <div className={cn(styles.body, '!px-2.5 sm:!px-5')}>
        <div className={styles.table_subject}>
          <p className={styles.table_subject_label}>Show for Address</p>

        </div>
        <div className={styles.accountWrap}>
          <p className={cn(styles.accountItem,'hidden sm:block' )}>
            {(userAddress)}
          </p>
          <p className={cn(styles.accountItem, 'sm:hidden')}>
            {encryptionAddress(userAddress)}
          </p>
        </div>
        <div className={cn(styles.tableWrap, 'overflow-x-auto')}>
          <Table
            columns={[
              { dataIndex: 'tx', label: 'Tx' },
              { dataIndex: 'block', label: 'Block' },
              { dataIndex: 'block_time', label: 'Block Time' },
              { dataIndex: 'source', label: 'Source' },
              { dataIndex: 'type', label: 'Type' },
              // { dataIndex: 'descript', label: 'Description' }
            ]}
            data={list.map((item) => {
              const itemInfo = {} as AddressType

              const tx = item['tx_hash'] || item['order_hash'] || item['id'] || ''
              let shortHash = ''

              if (tx) {
                shortHash = tx.substr(tx.length - 5)
                if (tx.length == 128) {
                  shortHash += '...' + tx?.substr(64, 5)
                }
                // else if (tx.length == 129) {
                //   shortHash += '...' + tx?.substr(65, 5)
                // }
              }

              return {
                id: item.tx_index,
                source: itemInfo.label ?? '',
                block: item.block_index,
                block_time: dayjs(item._block_time).format(),
                type: item['_category'],
                descript: '',
                tx: shortHash,
              }
            })}
            noData={<TableFooter />}
          />
        </div>
      </div>
    </div>
  )
}
