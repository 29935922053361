import * as bip39 from 'bip39'
import { payments, networks, bip32, address as BitcoinAddres, Transaction, Psbt, TransactionBuilder, script, opcodes } from 'bitcoinjs-lib';
import crypto from 'crypto-js'
import { PrivateKey } from 'bitcore-lib';
import { LOCAL_BTC_ADDRESS_KEY } from '@/contains'
import { jotaiStore } from '@/store'
import { addressAtom } from '@/store/user'
import { message } from 'antd';
import { bitcoin } from 'bitcoinjs-lib/types/networks';

export const encryptionAddress = (address?: string, start = 6, end = 4) => {
    if (!address) return ''
    return `${address.slice(0, Math.max(0, start))}...${address.slice(-end)}`
}

export const toBase64 = (address: string) => {
    //used for storing address alias data, for instance
    return crypto.SHA256(address).toString(crypto.enc.Base64);
}

export const checkWalletConnection = async () => {
    if (window.unisat) {
        try {
            const accounts = await window.unisat.getAccounts();
            if (accounts.length > 0) {

                jotaiStore.set(addressAtom, accounts[0])
                return accounts[0]
            } else {
                console.log("Wallet is not connected");
                return null;
            }
        } catch (error) {
            console.error("Error checking wallet connection:", error);
            return null;
        }
    } else {
        console.log("Unisat Wallet not found");
        return null;
    }
}


export const disconnectWallet = async () => {
    jotaiStore.set(addressAtom, '')
    localStorage.removeItem(LOCAL_BTC_ADDRESS_KEY);
    await window?.unisat?.disconnect()
}

export const connectWallet = () => {
    return new Promise(async (resolve, reject) => {
        if (window.unisat) {
            try {
                const accounts = await window.unisat.requestAccounts();

                localStorage.setItem(LOCAL_BTC_ADDRESS_KEY, accounts[0]);
                jotaiStore.set(addressAtom, accounts[0])
                resolve('success')
            } catch (error) {
                reject()
                message.error('Error connecting to wallet')
                console.error("Error connecting to wallet:", error);
            }
        } else {
            // reject('connect unisat wallet error')
            // toast.error('Unisat Wallet not found')
        }
    })

}

export const getStoredWalletAddress = () => {
    const address = localStorage.getItem(LOCAL_BTC_ADDRESS_KEY);
    if (address) {
        // console.log("Restoring wallet address from storage:", address);
        jotaiStore.set(addressAtom, address)

        return address
    } else {
        return null;
    }
}

export const restoreConnection = async () => {
    const storedAddress = getStoredWalletAddress();

    if (storedAddress) {
        const currentAddress = await checkWalletConnection();
        if (currentAddress !== storedAddress) {
            await connectWallet();
        } else {
            jotaiStore.set(addressAtom, storedAddress)
        }
    } else {
        await connectWallet();
        console.log("No previous wallet connection found");
    }
}

export const getAddressPubKey = async (address: string) => {
    try {
        const pubkey: string = await window.unisat.getPublicKey(address)
        return pubkey
    } catch (e) {
        console.log(e)

        return ''
    }
}

export const getUtxoInfo = async (hexTransaction: string, userAddress: string) => {
    const tx = Transaction.fromHex(hexTransaction);

    const response = await fetch(`https://blockstream.info/testnet/api/address/${userAddress}/utxo`);
    const data = (await response.json()) ?? [];

    const utxo = data?.[data.length - 1] ?? {}
    const res = await fetch(`https://blockstream.info/testnet/api/tx/${utxo?.txid}/outspend/${utxo?.vout}`)
    const rs = await res.json()

    if (!rs?.spent) {
        const outputResponse = await fetch(`https://blockstream.info/testnet/api/tx/${utxo?.txid}`);
        const outputData = await outputResponse.json();
        const output = outputData.vout[utxo?.vout];

        return output
    }

    return {}
}

export const getUtxos = async (userAddress: string) => {
    const response = await fetch(`https://blockstream.info/testnet/api/address/${userAddress}/utxo`);
    const data = (await response.json()) ?? [];

    const utxos = await Promise.all(data?.map(async (utxo: any) => {
        const res = await fetch(`https://blockstream.info/testnet/api/tx/${utxo?.txid}/outspend/${utxo?.vout}`)
        const rs = await res.json()

        if (!rs?.spent) {
            const outputResponse = await fetch(`https://blockstream.info/testnet/api/tx/${utxo?.txid}`);
            const outputData = await outputResponse.json();
            const output = outputData.vout[utxo?.vout];

            return { ...utxo, ...output }
        }


        return {}
    }))

    return utxos
    // const utxo = data?.[data.length - 1] ?? {}
}

export const hexToPSBTForUtxos = (hexString: string, utxos: any) => {
    const psbt = new Psbt({ network: networks.testnet });
    try {
        const tx = Transaction.fromHex(hexString);

        utxos.forEach((utxo: any) => {
            const txidBuffer = Buffer.from(utxo.txid, 'hex').reverse()

            const _input = {
                hash: txidBuffer,
                index: utxo.vout,
                witnessUtxo: {
                    script: Buffer.from(utxo.scriptpubkey, 'hex'), // 根据你的地址类型，填入scriptPubKey
                    value: utxo.value,
                },
            }
            psbt.addInput(_input);
        });


        for (const output of tx.outs) {
            psbt.addOutput({
                script: output.script,
                value: output.value,
            });
        }

        // 生成 PSBT 交易 hex
        const psbtHex = psbt.toBase64();

        return psbtHex
    } catch (e: any) {
        message.error(e.message || e)
        console.log(e)
    }
}

export const hexToPSBT = (hexString: string, address: string, utxo: any, fee?: number) => {
    try {
        const psbt = new Psbt({ network: networks.testnet });

        const tx = Transaction.fromHex(hexString);

        for (let i = 0; i < tx.ins.length; i++) {
            const input = tx.ins[i];
            const { prefix } = BitcoinAddres.fromBech32(address)
            const isSegwit = ['bc', 'tb'].includes(prefix)

            const _input: any = {
                hash: input.hash,
                index: input.index,
                sequence: input.sequence,
            }

            if (isSegwit) {
                _input['witnessUtxo'] = {
                    script: Buffer.from(utxo.scriptpubkey, 'hex'),
                    value: utxo.value
                }
            }

            psbt.addInput(_input);
        }

        for (const output of tx.outs) {
            psbt.addOutput({
                script: output.script,
                value: output.value,
            });
        }

        // 生成 PSBT 交易 hex
        const psbtHex = psbt.toBase64();

        return psbtHex
    } catch (e: any) {
        console.log('error: hexToPSBT2 -> ', e.message)
        return ''
    }
}

export const signMessageByUnisat = async (hex: string) => {
    return new Promise(async (resolve, reject) => {
        try {
            const signedString = await window.unisat.signPsbt(hex)

            resolve(signedString)
        } catch (e: any) {
            console.log('error: signMessageByUnisat -> ', e.message)
            reject(e.message)
        }

    })
}

export const broadcastTxByUnisate = async (signedTxString: string) => {
    return new Promise(async (resolve, reject) => {
        try {
            const rs = await window?.unisat?.pushPsbt(signedTxString)

            resolve(rs)
        } catch (e: any) {
            console.log('error: broadcastTxByUnisate -> ', e.message)
            reject(e.message)
        }

    })
}

export async function getBitcoinFees() {
    const url = 'https://blockstream.info/testnet/api/fee-estimates';

    try {
        const response = await fetch(url);
        const data = await response.json();

        return data
    } catch (error) {
        console.error("Error fetching Bitcoin fees:", error);
        return {}
    }
}

export const randomName = () => {
    const firstDigit = Math.floor(Math.random() * 9) + 1; // 确保第一位不是0
    let digits = firstDigit.toString();

    for (let i = 0; i < 18; i++) {
      digits += Math.floor(Math.random() * 10); // 生成其余的4位数字
    }

    const name = 'A' + digits;

    return name
}